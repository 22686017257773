import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Menu, message } from "antd";
import config from "src/commons/config-hoc";
import { getLoginUser } from "@ra-lib/admin";
import styles from "./style.less";

export default config({
    path: "/front/idss",
    title: "智能决策分析",
})(function IdssComponent(props) {
    const [menuItems, setMenuItems] = useState([]);
    const [currentUrl, setCurrentUrl] = useState("");
    const [selectedKey, setSelectedKey] = useState(null);

    const fetchMenuItems = useCallback(async () => {
        try {
            const response = await props.ajax.get("/api/agent/worklows/availablebots", { orgId: getLoginUser().orgId });
            if (response.data) {
                const transformedItems = response.data.map(item => ({
                    key: item.workflowId,
                    label: item.workflowName,
                    url: item.appUrl,
                }));

                setMenuItems(transformedItems);

                // 默认选择第一个菜单项
                if (transformedItems.length > 0) {
                    setCurrentUrl(transformedItems[0].url);
                    setSelectedKey(transformedItems[0].key);
                }
            } else {
                throw new Error("无效的数据格式");
            }
        } catch (error) {
            console.error("Error fetching menu items:", error);
            message.error(error.message || "获取菜单项失败，请稍后重试。");
        }
    }, [props.ajax]);

    useEffect(() => {
        fetchMenuItems();
    }, [fetchMenuItems]);

    const handleMenuClick = useCallback((item) => {
        setSelectedKey(item.key);
        setCurrentUrl(item.url);
    }, []);

    const menuItemsRender = useMemo(() => (
        menuItems.map((item) => (
            <Menu.Item key={item.key} onClick={() => handleMenuClick(item)}>
                    {item.label}
            </Menu.Item>
        ))
    ), [menuItems, selectedKey, handleMenuClick]);

    return (
        <div style={{ display: "flex", height: "100vh", width: "100%", overflow: "hidden", padding: "5px" }}>
            <div className={styles.leftMenu}>
                <Menu
                    mode="vertical"
                    selectedKeys={selectedKey ? [selectedKey] : []}
                >
                    {menuItemsRender}
                </Menu>
            </div>

            {/* 右侧 iframe 显示区域 */}
            <div style={{ flex: 1, overflow: "hidden", height: "100%"}}>
                {currentUrl ? (
                    <iframe
                        src={currentUrl}
                        style={{
                            width: "100%",
                            height: "95%",
                            border: "none",
                            borderRadius: "5px",
                            overflow: "hidden",
                        }}
                        title="智能决策"
                    />
                ) : (
                    <div>加载中...</div>
                )}
            </div>
        </div>
    );
});